import {AddToFunnelDialog} from "./add_to_funnel_dialog.js";

export class CustomBulkOperations {
    constructor({doctype}) {
        if (!doctype) frappe.throw(__("Doctype is not provided"));
        this.doctype = doctype;
    }

    // Add to funnel
    add_to_funnel(docnames, done) {
        if (docnames.length > 0) {
            const doctype = this.doctype;
            frappe.call({
                method:
                    "nextai.funnel.doctype.funnel_task.api.get_manual_trigger_funnels",
                args: {
                    doctype: doctype,
                },
                callback: function (response) {
                    const options =
                        response?.message?.map((item) => ({
                            label: item?.parent || "",
                            value: item?.name || "",
                        })) || [];

                    var unassign_from = new AddToFunnelDialog({
                        obj: this,
                        method:
                            "nextai.funnel.doctype.funnel_task.triggers.on_manual_trigger.trigger",
                        doctype: doctype,
                        docnames: docnames,
                        callback: done,
                        options: options,
                    });
                    if (options?.length) {
                        unassign_from.dialog.clear();
                        unassign_from.dialog.show();
                    } else {
                        unassign_from.prompt();
                    }
                },
            });
        } else {
            frappe.msgprint(
                __("Select a single or multiple entries to add in funnel")
            );
        }
    }
}
