export class AddToFunnelDialog {
  constructor(opts) {
    $.extend(this, opts);
    this.make();
    this.set_description_from_doc();
  }
  make() {
    var me = this;
    this.dialog = new frappe.ui.Dialog({
      title: __("Add to funnel"),
      fields: this.get_fields(),
      primary_action_label: __("Submit"),
      primary_action: function () {
        var args = me.dialog.get_values();
        if (args && args.funnel_name) {
          me.dialog.set_message(__("Adding") + "...");
          me?.docnames?.forEach((docname) => {
            frappe.call({
              method: me.method,
              args: $.extend(args, {
                doctype: me.doctype,
                doctype_name: docname,
                trigger_name: args.funnel_name,
              }),
              btn: me.dialog.get_primary_btn(),
              callback: function (r) {
                if (!r.exc) {
                  if (me.callback) me.callback(r);
                  me.dialog && me.dialog.hide();
                } else {
                  me.dialog.clear_message();
                }
              },
            });
          });
        }
      },
    });
    this.prompt = function () {
      frappe.msgprint({
        title: __("Add to funnel"),
        message: __(
          "There is no related trigger found. Do you want to create one?"
        ),
        primary_action: {
          label: "Create funnel",
          action() {
            frappe.set_route("app/funnel");
          },
        },
      });
    };
  }
  set_description_from_doc() {
    if (this.frm && this.frm.meta.title_field)
      this.dialog.set_value(
        "description",
        this.frm.doc[this.frm.meta.title_field]
      );
  }
  get_fields() {
    var me = this;
    return [
      {
        fieldtype: "Select",
        fieldname: "funnel_name",
        label: __("Funnel name"),
        reqd: true,
        options: me.options,
      },
    ];
  }
}
