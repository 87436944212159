import { CustomBulkOperations } from "./libs/custom_bulk_operations.js";

frappe.views.ListView = class ListView extends frappe.views.ListView {
  get_actions_menu_items() {
    var me = this,
      doctype = this.doctype,
      actions_menu_items = super.get_actions_menu_items(),
      bulk_operations = new CustomBulkOperations({ doctype: this.doctype }),
      is_field_editable = function (field_doc) {
        return (
          field_doc.fieldname &&
          frappe.model.is_value_type(field_doc) &&
          field_doc.fieldtype !== "Read Only" &&
          !field_doc.hidden &&
          !field_doc.read_only &&
          !field_doc.is_virtual
        );
      },
      has_editable_fields = function (doctype) {
        return frappe.meta.get_docfields(doctype).some(function (field_doc) {
          return is_field_editable(field_doc);
        });
      },
      // Add to funnel
      bulk_add_to_funnel = function () {
        return {
          label: __("Add to funnel", null, "Button in list view actions menu"),
          action: function () {
            me.disable_list_update = true;
            bulk_operations.add_to_funnel(
              me.get_checked_items(true),
              function () {
                me.disable_list_update = false;
                me.clear_checked_items();
                me.refresh();
              }
            );
          },
          standard: true,
        };
      },
      idx = 0;
    // bulk edit
    if (has_editable_fields(doctype)) idx++;

    actions_menu_items.splice(0, 0, bulk_add_to_funnel());

    return actions_menu_items;
  }
};
